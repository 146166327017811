import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import { Form, Row, Col, Alert, Button, InputGroup, FormControl } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight, faArrowDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import '../style/guest.css'
import faunadb from 'faunadb'

const q = faunadb.query
const client = new faunadb.Client({
    secret: 'fnAD30kaBUACBCJGdrPiXAHlLtlibF_S6aDSAK2B'
})
library.add(faArrowRight, faArrowDown, faSearch);

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.nama = React.createRef()
        this.state = {
            dataawal: [],
            datatampil: [],
            search: null,
            clicked: 'x'
        }
    }
    async componentDidMount() {
        try {
            let allDocuments = await client.query(
                q.Map(
                    q.Paginate(q.Match(q.Index("all_customers")), {
                        size: 10000,
                    }),
                    q.Lambda("X", q.Get(q.Var("X")))
                )
            )
            let data = []
            allDocuments.data.map(val => {
                data.push({
                    nama: val.data.nama,
                    alamat: val.data.alamat,
                    pesan: val.data.pesan,
                    mempelai: val.data.mempelai,
                    hadir: val.data.hadir,
                    sesi: val.data.sesi ? val.data.sesi : null,
                    with: val.data.with ? val.data.with : null,
                    id:val.ref.value.id
                })
            })
            this.setState({ dataawal: data })
            if (localStorage.getItem('nama')) {
                if (localStorage.getItem('nama') == 'admin-alldata') {
                    this.setState({
                        datatampil: data, search: true
                    })
                } else {
                    let datatampil = data.filter(val => { return val.mempelai == localStorage.getItem('nama') })

                    if (datatampil.length > 0) {
                        this.setState({
                            datatampil: datatampil, search: true
                        })
                    } else {
                        this.setState({
                            search: false
                        })
                    }
                }
            }


        } catch (e) {
            console.log(e)
        }
    }
    Delete = async(id) => {
        await client.query(q.Delete(q.Ref(q.Collection('customers'), id)))
            .then((ret) => console.log(ret))
    }
    handleSubmit = () => {
        let nama = this.nama.current.value
        let { dataawal } = this.state
        if (nama == 'admin-alldata') {
            this.setState({
                datatampil: dataawal, search: true
            })
            localStorage.setItem('nama', nama)
        } else {
            let datatampil = dataawal.filter(val => { return val.mempelai == nama })

            if (datatampil.length > 0) {
                this.setState({
                    datatampil: datatampil, search: true
                })
                localStorage.setItem('nama', nama)
            } else {
                this.setState({
                    search: false
                })
            }
        }
    }

    render() {
        let { search, datatampil, clicked } = this.state

        return (
            <>
                <div id='guest'>
                    <Container>
                        <h1>
                            Buku Tamu
                        </h1>
                        <InputGroup className="mb-3">
                            <FormControl ref={this.nama}
                                placeholder="Mempelai"
                                aria-label="Mempelai"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <Button onClick={() => { this.handleSubmit() }}
                                    variant="outline-secondary" className='m-2 bg-main'><FontAwesomeIcon icon={faSearch} className='mt-auto mb-auto' /></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <div className='hasil'>


                            {
                                search == false ? (
                                    <Alert variant='light'>
                                        Wah sepertinya ada yang salah, coba lagi!
                                    </Alert>
                                ) : (search == true ? (
                                    <table>{
                                        datatampil.map((val, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {val.nama}
                                                    </td>
                                                    <td>
                                                        {val.mempelai}
                                                    </td>
                                                    <td>
                                                        {val.pesan}
                                                    </td>
                                                    <td onClick={() => { this.Delete(val.id) }}>
                                                        hapus
                                                    </td>
                                                </tr>)
                                        })}
                                    </table>) : (false))
                            }
                        </div>

                    </Container>

                </div>
            </>
        )
    }
}